import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Expanded hunting approved on 4 Arizona wildlife refuges in 2021`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "715px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fecebb7d47668865a7cd8ebae3237ade/d33ce/expanded-hunting-wide.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZRlOF//xAAbEAACAQUAAAAAAAAAAAAAAAAAAQMCBBESE//aAAgBAQABBQLvIh3MmN6mf//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEREiEy/9oACAEBAAY/Aoszs1n/xAAZEAADAAMAAAAAAAAAAAAAAAAAASERcZH/2gAIAQEAAT8hcToZah3Mb2f/2gAMAwEAAgADAAAAEP8A7//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxCAv//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EBM//8QAGBABAAMBAAAAAAAAAAAAAAAAAQARITH/2gAIAQEAAT8QBxq62qKDUGIXrqx//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of hunter with prey",
            "title": "Image of hunter with prey",
            "src": "/static/fecebb7d47668865a7cd8ebae3237ade/d33ce/expanded-hunting-wide.jpg",
            "srcSet": ["/static/fecebb7d47668865a7cd8ebae3237ade/e07e9/expanded-hunting-wide.jpg 200w", "/static/fecebb7d47668865a7cd8ebae3237ade/066f9/expanded-hunting-wide.jpg 400w", "/static/fecebb7d47668865a7cd8ebae3237ade/d33ce/expanded-hunting-wide.jpg 715w"],
            "sizes": "(max-width: 715px) 100vw, 715px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`With approval from the U.S. Fish and Wildlife Service, expanded hunting opportunities on national wildlife refuges for the 2020-2021 seasons now include Cabeza Prieta, Cibola, Buenos Aires and Leslie Canyon wildlife refuges.`}<br parentName="p"></br>{`
`}{`Hunting opportunities are authorized and provided on USFWS-managed lands in accordance with federal, state and tribal (where applicable) regulations and seasons.
The amendments, unanimously passed by the Arizona Game and Fish Commission during a public meeting in 2020 in Greer, broaden opportunities to hunt several species of small game, migratory birds and predators/furbearers in these refuges.
With the approval, the 2,765-acre Leslie Canyon refuge is open to hunters for the first time since its establishment in 1988.`}</p>
    <p>{`The parcel tucked into the Chiricahua Mountains protects a rare riparian region.`}</p>
    <p>{`The other three southern Arizona refuges with expanded hunting are the 117,464-acre Buenos Aires, the 18,444-acre Cibola on the lower Colorado River and the 860,000-acre Cabeza Prieta.`}</p>
    <p>{`Many predators such as mountain lions, coyotes and bobcats are included in the expanded hunting proposal.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      